#sheep {
  display: flex;
  flex-direction: column;
  color: #e5e5e5;
  .sheep-image {
    width: 100%;
    height: auto;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .sheep-image-position {
      position: absolute;
      height: 40%;
      width: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgba(0,0,0,0.4);
      // padding: 80px;
      // box-sizing: border-box;
      p {
        font-size: 26px;
        font-weight: bold;
        margin: 0px;
        padding: 0px 20px;
        margin-bottom: 20px;
      }
      .sheep-image-padding{
        width: 60%;
      }
    }
  }
  .sheep-show-top {
    font-size: 26px;
    font-weight: bold;
    padding: 40px 60px;
  }
  .sheep-show {
    padding: 10px 60px;
    display: flex;
    color: #e5e5e5;
    .sheep-show-left {
      flex: 1;
      padding: 0px 0px 60px 0px;
      box-sizing: border-box;
      img {
        width: 90%;
        height: auto;
      }
    }
    .sheep-show-right {
      flex: 1;
    }
  }
  .sheep-show-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #262626;
    .sheep-show-bottom-title {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 50px;
      font-size: 26px;
      font-weight: bold;
    }
    .sheep-show-bottom-content {
      width: 100%;
      display: flex;
      justify-content: space-around;
      .sheep-show-bottom-content-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 50px;
          height: 50px;
          margin-bottom: 20px;
        }
        p {
          margin:10px 0px;
          font-size: 22px;
          font-weight: bold
          ;
        }
      }
    }

  }
  .sheep-show-footer {
    height: 600px;
    position: relative;
    .image-position {
      height: 300px;
      position: absolute;
      img {
        height: 100%;
        width: auto;
      }
    }
    .image1 {
      top: 50px;
      right: 350px;
      z-index: 10;
    }
    .image2 {
      top: 250px;
      right: 600px;
      z-index: 5;
    }
    .image3 {
      height: 400px;
      top: 40px;
      right: 1000px;
    }
  }
}
@media screen and (max-width: 768px) {
  #sheep{
    .sheep-image-position{
      top: 20%!important;
      width: 90%!important;
      padding:0px!important;
      .sheep-image-position-p{
        font-size: 16px!important;
        margin-bottom: 10px!important;
      }
      .sheep-image-position {
        position: absolute;
        height: 40%;
        width: 90%;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(0,0,0,0.4);
        padding: 0px!important;
        // box-sizing: border-box;
        p {
          font-size: 26px;
          font-weight: bold;
          margin: 0px;
          padding: 0px 20px;
        }
        .sheep-image-padding{
          width: 60%;
          font-size: 12px!important;
        }
      }
    }
    .sheep-show-footer {
      height:auto;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      .image-position {
        // height: 300px;
        position: relative!important;
        img {
          height: auto;
          width: 100%;
        }
      }
      .image1 {
        top: 50px;
        right: 0px!important;
        z-index: 10;
      }
      .image2 {
        margin-top: 30px;
        top: 10px;
        right: 0px!important;
        z-index: 5;
      }
      .image3 {
        // height: 400px;
        top: 0px;
        right: 0px!important;
        margin-left: 20% ;
        img{
          width: 60%;
        }
      }
    }
  }
  
}
