#contactus-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  .contact-page-image {
    position: relative;
    width: 100%;
    height: 420px;

    img {
      width: 100%;
      height: 100%;
    }

    .contactus-home-position {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;

      .contactus-button {
        margin-top: 30px;
        background-color: #1D4ED8;
        padding: 20px 10px;
        border-radius: 10px;
        min-width: 400px;
        color: #fff;
        text-align: center;

        .yfjs-text-white {
          margin-left: 20px;
          font-weight: bold;
        }
      }

    }


  }

  .contact-page-show {
    width: 860px;
    background-color: #262626;
    padding: 80px 60px;

    .yfjs-title-blue {
      margin-bottom: 60px;
    }
  }

}

@media screen and (max-width: 768px) {
  #contactus-page {
    .contact-page-image {
      height: 220px !important;
    }

    .contact-page-show {
      width: 90%;
      background-color: #262626;
      padding: 20px;

      .yfjs-title-blue {
        margin-bottom: 20px;
      }
    }

  }

}