#screen {
  display: flex;
  flex-direction: column;

  .screen-image {
    width: 100%;
    height: auto;
    .agent-icon{
      position: absolute;
      top: 100px;
      right:50px;
      width: 60%;
      height: auto;
    }
    .agent-icon1{
      position: absolute;
      top: 100px;
      left:50px;
      width: 150px;
      height: 150px;
    }
    img {
      width: 100%;
      height: 100%;
    }

    .screen-image-position {
      position: absolute;
      /* height: 200px; */
      // height: 50%;
      width: 500px;
      top: 50%;
      left: 5%;
      transform: translateY(-50%);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      padding: 20px 30px;
      background-color: rgba(72, 72, 160, 0.2);

      .screen-image-position-p {
        font-size: 26px;
        font-weight: bold;
        margin: 0px;
      }

      .screen-image-position-text {
        margin: 0px;
        padding: 0px 60px;
        margin-top: 20px;
      }

      .screen-image-position-button {
        width: 100%;
        padding: 10px 20px;
        background-color: #2563EB;
        margin-top: 30px;
        text-align: center;
      }
    }

  }

  .screnn-show {
    padding: 40px 60px;
    .list-card{
      margin-bottom: 20px;
    }
    .container-content {
      display: flex;
      align-items: center;
      background-color: #fff;
      padding: 20px;
      border-radius: 4px;
      justify-content: space-between;
      height: 240px;
      .screnn-conent {
        display: flex;
        width: 45%;
        height: 100%;
        .rt-card {
          display: flex;
          justify-content: space-between;

          .rt-time1 {
            width: 30%;
            color: #333;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            .rt-day1 {
              font-size: 32px;
              font-weight: 700;
              
            }
          }

          .rt-content {
            height: 100%;
            width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            color: #333333;

            .content-top {
              // margin-bottom: 20px;
              font-size: 1.2rem;
              font-weight: 700;
            }

            .content-desc {
              color: #666666;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              font-size: 14px;
            }
          }
        }
      }
    }

    .screnn-card {
      height: 240px;
      display: flex;
      border-radius: 4px;
      overflow: hidden;
      img{
        max-width: 100%; /* 添加这行代码 */
      max-height: 100%; /* 添加这行代码 */
        object-fit: contain;
      }
      // align-items: center;
      .card-content {
        background-color: #fff;
        padding: 20px;
        color: #262626;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 1;
      }
    }

    .screnn-show-top {
      width: 100%;
      display: flex;
      padding: 20px 0px;
    }

    .screnn-show-top .screen-show-button {
      width: 149px;
      height: 38px;
      margin-right: 30px;
      border: 1px solid #2563EB;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .screnn-show-top .button-arrow {
      background-color: #2563EB;
      position: relative;
    }

    .screnn-show-top .button-arrow::after {
      content: '';
      position: absolute;
      top: 100%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0px 10px;
      border-color: #2563EB transparent transparent transparent;
    }

    .screnn-show-bot {
      height: auto;
      width: 100%;
      background: linear-gradient(to right, #262626 0%, #5F5F5F 100%);
      display: flex;

      .screnn-show-bot-left {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 80px;

        .screnntop {
          display: flex;
          align-items: center;

          img {
            width: 40px;
          }

        }

        .scrennbottom {
          margin-top: 20px;
        }

        .scrennbottom .yfjs-text-white {
          margin-top: 20px;
        }
      }

      .screnn-show-bot-right {
        flex: 1;
        height: auto;
        padding: 40px 60px 40px 20px;

        .screnn-show-bot-right-border {
          border: 10px solid transparent;
          /* 设置边框宽度并将边框颜色设为透明 */
          border-image: linear-gradient(115.38deg, #3C71FB 10.72%, #4DFFBE 102.84%) 1;
          /* 设置渐变边框 */
          display: flex;
        }



        // img {
        //   border: 10px solid transparent; /* 设置边框宽度并将边框颜色设为透明 */
        //   border-image: linear-gradient(115.38deg, #3C71FB 10.72%, #4DFFBE 102.84%) 1; /* 设置渐变边框 */
        //   width: 100%;
        //   height: auto;
        //   max-height: 100%;
        // }
      }

    }

  }

  .screnn-audio {
    padding: 20px 60px;
    width: 100%;

    .font-title {
      margin-bottom: 30px;
    }

    .screnn-audio-content {
      height: 100%;
      width: 100%;

      .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .screnn-swiper {
    padding: 20px 60px;
    width: 100%;

    .font-title {
      margin-bottom: 30px;
    }

    .screnn-swiper-content {
      height: 100%;
      width: 100%;

      .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .advantage{
    padding: 40px 60px;
    .title{
      font-size: 24px;
      color: #fff;
      margin-bottom: 30px;
    }
    .advantage-card{
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: 10px;
      border-radius: 4px;
      height: 200px;
      border: 1px solid #FFFFFF33;
      color: #fff;
      .adv-icon{
        height: 93px;
        width: 93px;
      }
      .adv-title{
        font-size: 16px;
        font-weight: 700;
      }
      .adv-desc{
        font-size: 14px;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #screen {
    display: flex;
    flex-direction: column;

    .screen-image {
      width: 100%;
      height: auto;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .screen-image-position {
        width: auto;
        right: 15px;
        top: 20%;

        .screen-image-position-p {
          font-size: 12px;
        }

        .screen-image-position-text {
          font-size: 10px;
          margin-top: 5px;
        }

        .screen-image-position-button {
          margin-top: 10px;
        }
      }

    }

    .screnn-show {
      padding: 20px 30px;

      .screnn-show-top .screen-show-button {
        margin-right: 0;
      }
      .screnn-card{
        height: 100%;
        flex-direction: column;
      }
      .container-content{
        height: 100%;
        flex-direction: column;
        .screnn-conent{
          width: 100%;
        }
      }
    }

    .screnn-show-bot-left {
      padding: 10px !important;
    }
    .screnn-swiper {
      padding: 10px;
     .font-title {
        font-size: 18px;
      }
    }
   .advantage {
      padding: 20px 10px;
     .title {
        font-size: 18px;
      }
     .advantage-card {
        height: auto;
        padding: 10px;
       .adv-icon {
          height: 60px;
          width: 60px;
        }
       .adv-title {
          font-size: 14px;
        }
       .adv-desc {
          font-size: 12px;
        }
      }
    }
  }

}