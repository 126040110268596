#designfarm {
    .sheepfarm-image {
      width: 100%;
      position: relative;
      margin-bottom:15%;
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center;
      }
      .sheepfarm-image-position {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 65%;
        height: 80%;
        transform: translate(-50%,0);
        background-color: rgba(0,0,0,0.5);
        padding: 60px 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        color: #e5e5e5;
        .sheepfarm-title {
          font-weight: 400;
          line-height: 72px;
          text-align: left;
        }
        .sheepfarm-text {
          // font-weight: 700;
          font-size: 18px;
          line-height: 30px;
          letter-spacing: -0.40000000596046px;
          text-align: left;
        }
        .sheepfarm-button {
          font-size: 16px;
          background-color: #2563eb;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
        }
      }
    }
    .sheepfarm-contaner{
      padding: 30px 15%;
    }
    .sheepfarm-show {
      margin-bottom: 30px;
      padding: 0px 15%;
      div {
        padding-left: 20px;
      }
    }
    .sheepfarm-bottom {
      margin-bottom: 200px;
    }
  }
  
  @media screen and (max-width: 768px) {
    #designfarm{
      .sheepfarm-image {
        margin-bottom: 20px;
      }
      .sheepfarm-image-position{
        transform: translate(-50%,-50%)!important;
        width: 80%!important;
        padding: 0px 30px!important;
        .sheepfarm-text{
          padding: 0px!important;
        }
        .sheepfarm-button{
          margin-top: 0px!important;
        }
      }
      .sheepfarm-image {
        height: 300px;
      }
      .sheepfarm-image img {
        height: 100% !important;
      }
      .sheepfarm-show{
        padding: 0px 10%;
      }
  
    }
    
  }
  