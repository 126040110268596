.header-tabs {
  display: flex;
  .header-tab {
    position: relative;
  }
  .disply-none {
    display: none !important;
  }
  .header-tab-position {
    position: absolute;
    bottom: -100%px;
    left: 0px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    // width: 300px;
    // height: 200px;
    // padding: 0px 60px 20px 30px;
    padding: 10px 20px;
  }
  .yfjs-title-bold {
    margin: 0px;
    margin-left: 20px;
  }
}
.header-tab-position-image {
  display: flex;
  padding: 20px;
  min-width: 200px;
  // margin-top: 20px;
  img {
    width: 25px;
    height: 25px;
  }
  p {
    margin: 0px;
    margin-left: 20px;
    white-space: nowrap;
  }
}
