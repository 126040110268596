#customers {
  .customer-card-center {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }
  .customer-card {
    flex: 1;
    height: 400px;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    p {
      margin: 0px;
    }
    .contain-title-border {
      margin-bottom: 20px;
      width: 40px;
      height: 5px;
    }
    .contain-title0 {
      margin-top: 5px;
      background-color: #fb923c;
    }
    .contain-title1 {
      margin-top: 5px;
      background-color: #60a5fa;
    }
    .contain-title2 {
      margin-top: 5px;
      background-color: #34d399;
    }
    .customer-card-contain {
      background-color: #262626;
      padding: 20px;
      flex: 1;
      overflow: auto;
      .contain-title {
        font-size: 22px;
      }
      .contain-text {
        font-size: 14px;
        line-height: 30px;
      }
      &:-webkit-scrollbar {
        width: 8px;
      }
    }
    img {
      width: 100%;
      max-height: 160px;
    }
  }
}

@media screen and (max-width: 768px) {
  #customers{
    .customer-card-center {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .customer-card {
      width: 100%;
      min-height: 400px;
      display: flex;
      flex-direction: column;
    }

  }
 
}
