.sider-position {
  position: fixed;
  z-index: 1000;
  right: 0px;
  top: 40%;
  .sider-position-boder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height:60px;
    background-color: #525252;
    border-radius: 50%;
    margin-bottom: 10px;
    position: relative;
    img{
      width: 50%;
    }
    .contact-position {
      position: absolute;
      top: -75px;
      left: -290px;
      background-color: white;
      color: black;
      padding: 20px 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      width: 250px;
      height: auto;
      img{
        width: 70;
      }
      p {
        margin: 10px;
      }
    }
    
    .contact-position-two {
      position: absolute;
      top: -30px;
      left: -280px;
      background-color: white;
      color: black;
      padding: 10px 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      p {
        margin: 10px;
      }
    }
    .contact-position-two::after {
      content: '';
      position: absolute;
      top: 50%;
      right: -30px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 60px 0 60px 60px;
      border-color: transparent transparent transparent white;
    }
    .contact-position::after {
      content: '';
      position: absolute;
      top: 50%;
      right: -30px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 60px 0 60px 60px;
      border-color: transparent transparent transparent white;
    }
  }
 
  .back-color {
    background-color: #F5F5F5;
  }
}

@media screen and (max-width: 768px) {
  .sider-position{
    .sider-position-boder {
      width: 40px;
      height: 40px;
    }
    .sider-position-boder img {
      width: 60%;
      height: auto;
    }
    .contact-position {
      top: -70px!important;
      left: -210px!important;
    }
    .contact-position-two {
      top: -40px!important;
      left: -280px!important;
    }

  }
   
}
