  .agent-swiper{
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .carousel-image{
      height: 600px;
      width: 650px;
    }
    .agent-swiper-card{
      display: flex;
      flex-direction: column;
      color: #fff;
      .swiper-card-title{
        font-size: 40px;
        font-weight: 700;
      }
      .swiper-card-desc{
        font-size: 20px;
      }
    }
  }



  @media screen and (max-width: 768px) {
    .agent-swiper {
      .carousel-image{
        height: 100px;
        width: 80px;
      }
      .agent-swiper-card{
        .swiper-card-title{
          font-size:16px;
        }
        .swiper-card-desc{
          font-size: 12px;
        }
      }
    } 
    
    
  }