#design {
  display: flex;
  flex-direction: column;
  color: #e5e5e5;
  .sheep-image {
    width: 100%;
    height: auto;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .sheep-image-position {
      position: absolute;
      height: 40%;
      width: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgba(0,0,0,0.4);
      // padding: 20px 200px;
      // box-sizing: border-box;
      p {
        font-size: 26px;
        font-weight: bold;
        margin: 0px;
        padding: 0px 20px;
        margin-bottom: 20px;
      }
      .sheep-image-padding{
        // width: 60%;
      }
    }
  }
  .sheep-show-top {
    font-size: 26px;
    font-weight: bold;
    padding: 40px 60px;
  }
  .sheep-show {
    padding: 10px 60px 60px 60px;
    display: flex;
    color: #e5e5e5;
    .sheep-show-left {
      flex: 1;
      // margin: 0px 40px 80px 0px;
      // box-sizing: border-box;
      display: flex;
      flex-direction: column;
      min-height: 300px;
      padding: 0px 30px;
    }
    
    .sheep-show-right {
      flex: 1;
      div{
        margin-top: 15px;
      }
    }
  }
  .sheep-show-bottom {
    padding: 60px 10%;
    background-color: #262626;
    display: flex;
    flex-direction: column;
    align-items: center;
    p{
      margin-bottom: 50px;
      width: 100%;
    }
    img{
      width: 100%;
    }
  }
  .sheep-show-footer {
    padding: 60px 10%;
    div{
      width: 100%;
    }
    img{
      width: 100%;
    }
  }
}
@media screen and (max-width: 768px) {
  #design{
    .sheep-image-position-p{
      font-size: 16px!important;
      margin-bottom: 10px!important;
    }
    .sheep-image {
      .sheep-image-position {
        height: auto!important;
        padding: 10px;
        p {
          font-size: 26px;
          font-weight: bold;
          margin: 0px;
          padding: 0px 20px;
        }
        .sheep-image-padding{
          width: 60%;
          padding: 0px!important;
          font-size: 12px!important;
        }
        .sheep-show-left{
          margin: 0px!important;
        }
      }
    }
    .sheep-show-top {
      padding: 20px 40px;
    }
    .sheep-show {
      padding: 20px 40px;
    }
  }
  
}
