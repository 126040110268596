#partner{
    .container{
        .container-center{
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .container-image-item{
            img{
                height: 50px;
                // width: auto;
            }
        }
    }
}
@media screen and (max-width: 768px) {
    #partner{
        .container{
            .container-center{
                flex-direction: column;
                .MuiGrid-root{
                    max-width: none;
                    flex: 1;
                }
            }
        }
    }
} 
