.home-image-position {
    position: relative;
    .backgroundImage {
      height: auto;
    }
    .home-image-text {
      // background-color: red;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-55%);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      // height: 30%;
      width: 80%;
    }
    .yfjs-text-white {
      width: 40%;
      padding:0px 80px;
      margin-top: 50px;
    }
    .yfjs-text-color {
      // width: 50%;
      background: linear-gradient(90deg, #3B82F6 0%, #1D4ED8 56%);
      padding: 10px 80px;
      border-radius: 10px;
      color: #f7f7f7;
      text-align: center;
      font-size: 14px;
      margin-top: 50px;
      p{
        margin: 0px;
      }
    }
  }

@media screen and (max-width: 768px) {
      .home-image-position{
        .backgroundImage {
          height: 200px;
        }
        .home-image-text{
          height: auto!important;
          width: 100%!important;
          display: flex;
          .font-title{
            width: 80%;
            font-size: 12px;
            margin-top: 5px;
          }
          .yfjs-text-white {
            width: 50%;
            padding:0px!important;
            margin-top: 10px;
            font-size: 10px;
          }
          .yfjs-text-color {
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 10px;
            margin-top: 10px;
          }
        }
      }
  } 
  