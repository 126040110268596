#contactfooter{
    .container-top {
        height: 183px;
        width: 100%;
        background: linear-gradient(to right, #3B82F6 50%, #3B82F6 100%);
        display: flex;
        font-size: 38px;
    
    
    }
    .container-right-img{
        display: flex;
        margin-top: 20px;
        img{
            height: 100px;
        }
        .img-first{
            margin: 0px 20px 0px 40px;
        }
    }
.container-top-left {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-top-right {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    padding-left: 200px;
    align-items: center;

    img {
        margin-left: 100px;
    }
}

.container-bottom {
    display: flex;
    align-items: center;

}

.container-left {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

}

.container-center {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;




}

.container-center-title {
    width: 300px;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 700;
    line-height: 35px;
    text-align: left;

}

.container-center-text {
    margin-top: 15px;
    width: 300px;
    display: flex;
    flex-wrap: wrap;

    p {
        margin: 10px 0px 0px 0px;
        flex: 1 0 50%;
        /* 设置每行两个元素 */
    }
}

.container-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;


}

.container-center-title {
    width: 300px;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 700;
    line-height: 35px;
    text-align: left;

}

.container-right-text {
    display: flex;
    align-items: center;
    margin-top: 15px;

    img {
        width: 20px;
        height: auto;
    }

    span {
        margin-left: 20px;
    }
}

.contact-out {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.container-contact {
    max-width: 100vw !important;
    padding: 0px !important;
    margin: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
}


@media screen and (max-width: 768px) {
    #contactfooter {
        .footer-gird-margin{
            margin-top: 30px;
        }



    }
   
  }
  