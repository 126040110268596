.join-modal-class{
  width: 50%;
}
#join {
  .join-image {
    height:420px;
    position: relative;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .join-imahe-position {
    position: absolute;
    right: 250px;
    top: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .yfjs-title-white {
      font-size: 36px;
      font-weight: bold;
    }
  
    .yfjs-text-white {
      font-size: 22px;
      font-weight: bold;
    }
  }
  .join-show {
    padding: 20px 100px 20px 100px;
  }

  .join-show-display {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    flex-wrap: wrap;
    .join-show-display-item {
      height: 400px;
      background-color: white;
      padding: 30px 30px 50px 30px;
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      .join-show-title{
        display: flex;
        color: black;
        margin-bottom: 30px;
        .yfjs-title-bold{
          margin-right: 20px;
  
        }
      }
    }
  }

 

  .join-show-display .join-show-display-item li {
    color: black;
  }

  .join-show-display .join-show-display-item .yfjs-text {
    margin-top: 20px;
  }

  .join-show-display .join-show-display-item .join-button {
    height: 50px;
    width: 40%;
    background-color: #3675FF;
    position: absolute;
    right: 0px;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .join-swiper {
    width: 100%;
    .swiper{
      width: 80%;
    }
    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 100%;
        width: auto;
      }
    }

  }
 
  
}


@media screen and (max-width: 768px) {
  .join-modal-class{
    width: 100%;
  }
  #join {
    .join-image {
      height:220px!important;
    }
    .join-show {
      padding: 20px !important;
    }
  
    .join-show-display-item {
      height: auto !important;
    }

  }
 
}

