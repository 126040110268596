
#contact-page {
  .contact-page-image {
    position: relative;
    margin-bottom: 80px;
    .contact1 {
      width: 100%;
      height: auto;
    }
    .contact2 {
      height: 60%;
      position: absolute;
      top: 50%;
      transform: translate(0px,-50%);
      left: 0px;
    }
    .contact-title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      text-align: center;
      padding-bottom: 30px;
      p{
        margin: 0px 0px 30px 0px;
        font-size: 30px;
        font-weight: bold;
      }
      span{
        font-size: 22px;
        text-align: center;
      }
    }
  }
  .contact-page-show-out{
    display: flex;
    .contact-page-show {
      flex: 1;
      padding: 20px;
      padding-left: 200px;
      p {
        width: 100%;
      }
    }
    .contact-page-show-out-image{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      // padding: 0px 10%;
      img{
        width: 60%;
        height: auto;
      }
    }

  }
  .contact-page-introduce{
    display: flex;
    gap: 80px;
    padding: 100px 200px;
    .introduce-left{
      width: 30%;
      padding: 0px 30px;
      img{
        width: 100%;
      }
      .introduce-left-image{
        position: relative;
      }
      .introduce-left-position{
        position: absolute;
        bottom: -30px;
        // height: 80px;
        width: 80%;
        left: 50%;
        transform: translate(-50%,0px);
        background-color: white;
        display: flex;
        gap: 15px;
        flex-direction: column;
        // justify-content: space-around;
        // padding: 10px 20px;
        padding:15px 20px;
        p{
          color: #262626;
          font-weight: bold;
          margin: 0px;
        }
      }

      .introduce-left-position::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 5px;
        background: #0368FF;
      }
    }
    .introduce-right{
      flex: 1;
      display: flex;
      flex-direction: column;
      .introduce-right-top{
        display: flex;
        flex-wrap: wrap;
        width: 100%; /* Ensure the container takes full width */
        .introduce-item{
          flex: 1 1 50%; /* Each item takes up 50% of the container's width */
          box-sizing: border-box; /* Includes padding and border in the element's total width and height */
          padding: 20px; /* Optional: adds padding inside each item */
          p{
            font-size: 46px;
            font-weight: bold;
            margin: 0px;
          }
          span{
            color: white;
            font-weight: 600;
          }
        }
        .introduce-item1 p{
          color: #bda7ff;
        }
        .introduce-item2 p{
          color: #34D399;
        }
        .introduce-item3 p{
          color: #FB923C;
        }
        .introduce-item4 p{
          color: #A78BFA;
        }
      }
      .introduce-right-bottom{
        margin-top: 20px;
        color: #E5E5E5;
      }
    }
  }
  .contact-page-bottom {
    min-height: 580px;
    padding: 0px 200px;
    background-color: #262626;
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    flex-wrap: wrap;
    .contact-page-bottom-first {
      height: 300px;
      padding: 20px;
      border-radius: 10px;
    }
    .contact-page-bottom-blue {
      height: 300px;
      background: linear-gradient(180deg,rgba(63,87,157,0.5) 0%,#1d4ed8 100%);
      padding: 20px;
      border-radius: 10px;
      .contact-bottom-title {
        font-size: 30px;
        font-weight: 700;
      }
    }
    .contact-item {
      height: 300px;
      background: linear-gradient(180deg,rgba(150,148,148,0.6) 0%,#404040 100%);
      padding: 20px;
      border-radius: 10px;
      .contact-bottom-title {
        color: #3b82f6;
        font-size: 30px;
        font-weight: 700;
      }
    }
  }
  .contact-page-swiper-bottom{
    // height: 800px;
    padding: 30px 200px;
    .contact-swiper{
      .swiper-slide{
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}






@media screen and (max-width: 768px) {
  #contact-page{
    .contact-page-image {
      margin-bottom: 10px!important;
      .contact-title {
        right:0px!important;
        p{
          font-size: 18px!important;
        }
        span{
          font-size: 12px!important;
        }
      }
    }
    .contact-page-show {
      // width: 375px;
      width: 100%;
      background-color: #262626;
      padding: 10px!important;
      p {
        width: 100%!important;
      }
      .yfjs-title-blue {
        margin-bottom: 20px;
      }
    }
    .contact-page-show-out{
      display: flex;
      flex-direction: column;
      padding: 30px;
    }
    .contact-page-bottom{
      padding: 10px!important;
    }
     
    .contact-page-introduce{
      display: flex;
      gap: 0px!important;
      padding: 0px!important;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
      .introduce-left{
        width: 80%!important;
        padding: 0px 30px;
      }
      .introduce-right{
        margin-top: 30px;
        .introduce-right-top{
          .introduce-item{
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
        .introduce-right-bottom{
          padding: 30px;
        }
      }
    }
  
  }
  .contact-page-swiper-bottom{
    // height: 800px;
    padding: 30px 0px!important;
    .contact-swiper{
      .swiper-slide{
       img{
        width: 100%;
       }
      }
    }
  }

  
}
