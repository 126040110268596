.product-card {
  width: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 32px;
  .bac-class {
    background-color: #fb923c;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bac-class-two {
    background-color: #34d399;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .first-p {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: -0.40000000596046px;
    text-align: center;
    margin-bottom: 0px;
  }
}
.gird-container {
  max-width: 1600px !important;
  .product-card-border{
    position: relative
  }
  .border0::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: #FB923C;
  }
  .border1::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: #34D399;
  }
  .product-card-image {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 40px 10px;
    img {
      border-radius: 10px;
      width: 90%;
      height: 90%;
    }
  }
}
