#solutions{
  .solutin-class {
    height: 617px;
    display: flex !important;
    align-items: center;
    .mySwiper{
      .swiper-slide{
        img{
          width: 100%;
          max-width: 100%;
          max-height: 100%;

        }
      }
    }
    .swiper {
      height: 100%;
      position: relative;
      .swiper-slide{
        display: flex;
        justify-content: center;
        img{
          height: 100%;
          width: auto;
        }
      }
    }
    .solution-position {
      position: absolute;
      height: 50%;
      width: 50%;
      top: 0px;
      bottom: 0px;
      right: 0px;
      left: 0px;
      margin: auto;
      background-color: rgba(0,0,0,0.5);
      padding: 45px 30px;
      box-sizing: border-box;
      // overflow-y: scroll;
      .top-text {
        font-family: Inter;
        font-size: 28px;
        font-weight: 700;
        line-height: 44px;
        letter-spacing: -0.40000000596046px;
        text-align: center;
      }
      .bottom-text {
        margin-top: 26px;
        font-family: Inter;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: -0.40000000596046px;
        text-align: left;
      }
    }
    .solutin-class-center {
      display: flex;
      justify-content: center;
    }
    .solutin-class-center-card {
      width: 50%;
      .center-card {
        background-color: rgba(0,0,0,0.5);
      }
    }
  }
}



@media screen and (max-width: 768px) {
  #solutions{
    .solutin-class{
      height: 200px;
      .solution-position{
        overflow-y: scroll;
      }
     }
  }
 
 
}
