#about{
  .container{
    padding:50px;
    position: relative;
  }
  .container::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 5px;
    height: 50%;
    background: #FB923C;
  }
  .container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 50%; /* Half the height of the container */
    background: #34D399; /* Second color */
  }
  .container-top{
    font-size: 18px;
    // font-weight: 600;
    line-height: 30px;
    text-align: left;
    color: #DFDFDF;
    margin-bottom: 20px;

  }
 
  .container-right{
    margin-top: 40px;
    .container-right-title{
      margin-top: 30px;
      font-size: 16px;
      color:#F5F5F5;
    }
    .container-right-text{
      margin-top: 30px;
      font-size: 14px;
      line-height: 24px;
      text-align: left;
      color: #D4D4D4;

    }
    .container-right-button{
      font-weight: bold;
      width: 200px;
      margin-top: 40px;
      color: #2563EB;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 30px;
      background-color: white;
      border-radius: 30px;
    }
  }


  .container-center {
    display: flex;
    .container-left {
      flex: 1;
      width: 100%;
      // padding: 30px 30px 60px 30px;
      .swiper-slide{
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 300px;
        img{
          height: 100%;
          width: auto;
        }
      }
    }
  }
}


@media screen and (max-width: 768px) {
  #about{
    .container{
      padding:20px;
    }
    .phone-style-about{
      display: flex;
      flex-direction: column!important;
      width: 100vw;
      // overflow: hidden;
      .container-center {
        display: flex;
        margin-top: 20px;
        .container-left {
          // flex: 1;
          // padding: 30px 30px 60px 30px;
          width:80vw!important;
          .swiper-slide{
            width: 100%;
            img{
              height: 100%;
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }

    }

  }
 
 
}

