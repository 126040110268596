#header{
    position: relative;
}
#header::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(to right, #2563EB 33.33%, #FB923C 33.33% 66.66%, #34D399 66.66%);
  }
.yfjs-button{
    padding: 8px 15px!important;
    border-radius: 4px!important;
    background-color: #1D4ED8!important;
}
.cusor-point{
    cursor: pointer;
}
.font-title{
    font-size: 26px;
    font-weight: bold;
}
.disply-none{
    display: none;
}
.yfjs-title{
    font-size: 20px;
    color: #000000;
}
.yfjs-title-bold{
    font-size: 20px;
    font-weight: bold;
    color: #000000;
}
.yfjs-text-bold{
    font-size: 16px;
    font-weight: bold;
    color: #000000;
}
.yfjs-text{
    font-size: 14px;
    color: #000000;
    margin-top: 5px;
}
.yfjs-title-white{
    font-size: 24px;
    font-weight: bold;
    color: white;
}
.yfjs-title-white-16{
    font-size: 16px;
    font-weight: bold;
    // color: white;
}
.yfjs-title-white-16-p{
    font-size: 16px;
    font-weight: bold;
    color: white;
    margin-bottom: 20px;
}
.yfjs-title-white-14{
    font-size: 14px;
    font-weight: bold;
    color: white;
}
.yfjs-text-white{
    font-size: 16px;
    color: white;
    margin-top: 5px;

}
.yfjs-title-blue{
    font-size: 24px;
    font-weight: bold;
    color: #3B82F6;
    margin: 0px;
}
.yfjs-slider-image{
    img{
        width: 100%;
        height: auto;
    }
}
.yfjs-display{
    display: flex;
    justify-content: center;
    align-items: center;
}
.yfjs-logo{
    height: 50px;
    width: auto;
}
.route-show-bottom-button {
    margin: 50px;
    background-color: #2563eb;
    width: 460.19px;
    height: 60px;
    border-radius: 6px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
  }