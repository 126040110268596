.agent-container {
    // text-align: center;
    padding: 20px;
    margin-bottom: 70px;
    .image-grid {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    
    .image-item {
        position: relative;
        width: 20%;
        height: 400px;
        // overflow: hidden;
    }
    
    .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease;
    }
    
    .icon {
        width: 96px;
        height: 96px;
    }
    
    .img-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    
        // background-color: rgba(4, 14, 46, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    
    .mask-title {}
    
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: opacity 0.3s ease;
        padding: 20px;
        .line{
            height: 2px;
            width: 10%;
            background-color: #fff;
            margin: 10px 0;
        }
    }
    
    .desc {
        font-size: 14px;
    
    }
    
    .active {
        opacity: 1;
        width: calc(100% + 40px);
        /* 宽度增加100px */
        height: calc(100% + 100px);
        /* 高度增加100px */
        background-color: rgba(0, 64, 255, 0.92);
        /* 示例颜色，可按需修改 */
        transform: translate(-50%, -50%);
        /* 使其居中 */
        left: 50%;
        top: 50%;
        border-radius: 10px 0 0 0;
        z-index: 999;
        /* 左上角有10px的圆角，可根据需求调整 */
    }
    
    
    .image-item:hover.overlay {
        opacity: 1;
    }
}


@media screen and (max-width: 768px) {
    .agent-container {
        .image-item {
            position: relative;
            width: 100%;
            height: 400px;
            // overflow: hidden;
        }
    }
    
}
